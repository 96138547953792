* {
  margin: 0;
  padding: 0;
  border: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  --border: #bfbfbf;
  --fill-bg-color: #FFFFFF;
  --color: #8c8c8c;
  --label-fill-bg-color: #bfbfbf;
  --primary-info-color: #434343;
  --secondary-info-color: #595959;
  /* --adm-color-primary: #fa541c; */
  --background-primary: #fff7e6;
  --whole-page-background: #F5F5F5;
  --padding-left: 12px;
  font-family: Avenir,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji',sans-serif;;
}

.body {
  flex: 1 1 auto;
  overflow-y: auto;
}

.bottom {
  flex: 0 1;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.flex-1 {
  flex: 1 1;
}

.center {
  justify-content: center;
}

.grid-demo-item-block {
  border: solid 1px #999999;
  background: var(--adm-color-box);
  text-align: center;
  color: #999999;
  height: 100%;
}

.default-whole-page {
  background-color: var(--whole-page-background);
}

.fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
}
a {
  text-decoration: none;
  color: #000;
}
input {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}