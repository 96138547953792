@font-face {
  font-family: 'number-1';
  src: url(./assets/font/Aberforth_Tiles.ttf);
}

@-webkit-keyframes move {
  0% {
      background-position: 0 0;
  }

  100% {
      background-position: 40px 0;
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

div {
  font-size: 15px;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: #000;
}

input,textarea {
  outline: none;
}

.adm-nav-bar {
  background-color: #00a3ff;
  color: white;
}

.adm-tabs-header {
  background: white;
}

.adm-nav-bar-back-arrow {
  font-size: 24px;
}

.adm-dropdown-item-highlight {
  color: white;
}

.aui-flexView {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.check-orange {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #cfcfcf;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.check-orange:checked {
  border-radius: 50%;
  background: url(./assets/images/checked.jpg) #ff4800 no-repeat center center;
  background-size: contain;
  color: #ffffff;
  text-align: center;
  font-weight: bolder;
}

.text-area {
  background-color: #f3f3f3;
  height: 60px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.label-2 {
  color: #3f3f3f;
}

.block-white {
  background-color: #ffffff;
  padding: 0 14px;
  margin-bottom: 12px;
}

.block-white>* {
  padding: 14px 0;
}

.border-chose {
  border: 1px solid #9f9f9f;
  color: #9f9f9f;
  display: inline-block;
  padding: 3px 6px;
  text-align: center;
}

.bordercl-orange {
  border-color: #ff4800;
  color: #ff4800;
}

.grid-6-6 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid-3-3-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid-2-10 {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.grid-10-2 {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.grid-3-9 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.grid-9-3 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid-3-9_2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr;
}

.grid-3-3-3-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}


.btn-aw {
  width: 100%;
  padding: 12px 0;
  border-radius: 6px;
}

.btn-orange {
  color: #ffffff;
  background-color: #ff4800;
  text-align: center;
}

.choose-span-1 span {
  font-size: 14px;
  text-align: center;
  padding: 6px 4px;
  border-radius: 3px;
  background: #ffffff;
  color: #000000;
}

.font-money {
  color: #ff0000;
}

.layer-shade {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background: #000000af;
  z-index: 100;
}
.layer-block {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #f3f3f3;
  z-index: 1;
}
.layer-block>div {
  margin: 12px 18px;
}
.keyboard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  text-align: center;
}
.keyboard span {
  border: 1px solid #f3f3f3;
  background: #ffffff;
  height: 40px;
  line-height: 40px;
}
.keyboard span:active {
  background: #8f8f8f!important;
  color: #ffffff;
  transition: ease-in-out 0.25s;
}
.key-del, .key-cur {
  background: #afafaf!important;
}
.key-del img {
  width: 18px;
}

.order-info-container {
  align-items: center;
  background-color: #fff7e6;
  bottom: 0;
  color: #873800;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  position: fixed;
  width: 100%;
}

.winOption {
  background: yellow;
}