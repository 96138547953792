/* Border styles */
#table thead,
#table tr {
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: rgb(211, 202, 221);
}

#table {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(211, 202, 221);
    text-align: center;
}


/* Padding and font style */
#table td,
#table th {
    padding: 5px;
    font-size: 12px;
    font-family: Verdana;
}


/* Alternating background colors */
#table tbody tr:nth-child(even) {
    background: #FFF
}

#table tbody tr:nth-child(odd) {
    background: rgb(193 219 250)
}